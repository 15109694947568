import * as React from 'react';
import _ from 'lodash';
import Helmet from 'react-helmet';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Query, withApollo, WithApolloClient } from 'react-apollo';
import * as qs from 'query-string';
import moment from 'moment';

import mockupRoutes from 'inkp-mockup-sdk/routes';
import { url, routes } from 'inkp-routes/public';
import { SHIPPING_SPEED_ENUM, Address } from 'inkp-order-sdk/types.g';
import { ORDER_STATUS_ENUM_MAPPING, SHIPPING_SPEED_ENUM_MAPPING } from 'inkp-order-sdk/order';
import { prettyProductSize } from 'inkp-product-sdk/product';
import { Query as QueryUserResults } from 'inkp-user-sdk/types.g';
import { isUserRegistered } from 'inkp-user-sdk/user';
import { prettyPrice } from 'infra-util/billing';

// Utils
import {
  GET_MY_ORDER_BY_ID_QUERY,
  GET_ORDER_BY_ID_AND_EMAIL_QUERY,
} from '../../../util/orders';
import { getCurrentUser } from '../../../util/login';
import { SIZES_BY_ORDER } from '../../../util/Product';
import { QUERY_CURRENT_USER } from '../../../util/Checkout';

// GTM helpers
import GTM from '../../../util/gtm';
import { GTMTypes } from '../../../interfaces/GTM';

// Components
import Loading from 'inkp-components/dist/Components/Loading';
import Logo from 'inkp-components/dist/Components/Logo';
import Image from 'inkp-components/dist/Components/Image';
import OrderSummary from 'inkp-components/dist/Components/OrderSummary';
import Alert from 'inkp-components/dist/Components/Alert';

class QueryCurrentUser extends Query<QueryUserResults> {}

const LOGO_SRC: string = 'https://inkp-production.32pt.com/public/logos/inkpop-wordmark.png';
const titleClassName = "color-navy-700 fw-bold";

const NameValueRow = ({ className, name, value }: { className?: string; name: JSX.Element | string | number; value: JSX.Element | string | number }) => {
  return (
    <div className={`flex justify-between ${className ? className : ''}`}>
      <div>{name}</div>
      <div>{value}</div>
    </div>
  );
}

interface RouteParams {
  orderId: string;
}

class OrderInvoice extends React.Component<WithApolloClient<RouteComponentProps<RouteParams>>> {
  private renderOrderNotFound(orderId: string) {
    return (
      <div className="flex flex-col w-full px-1 md:w-container md:mx-auto">
        <Alert
          alerts={[
            {
              type: 'error',
              title: 'Failed to lookup order',
              content: `Could not find order ${orderId}`,
            },
          ]}
        />
      </div>
    )
  }

  createOrderDetails({ order, user }: any) {
    const groupedProductItems: {[key: string]: any} = {};

    const {
      status,
      quote: { items },
      billingAddress,
      shippingAddress,
      features,
    } = order;

    const orderDetails = {
      orderId: order.id,
      status,
      dueAt: order.dueAt,
      orderedAt: order.orderedAt,
      amounts: order.quote.amounts,
      items: [] as any[],
      userInfo: {
        name: billingAddress.name,
        email: user.email,
      },
      payment: {
        cardNumber: (order.quote.payment && order.quote.payment.last4),
        cardType: (order.quote.payment && order.quote.payment.processorType),
      },
      billingAddress,
      shippingAddress,
    };

    items.forEach((item: any) => {
      const {
        product: { quantity, product, productItem },
        design,
        designId,
        amounts,
      } = item;

      // Get mockup from design
      const templateIdentifier: any = product.designTemplates.find((designTemplate: any) => {
        return designTemplate.side === design.sides[0].name;
      });
      const designColorHex = product.colors.find((color: any) => {
        return color.name === productItem.color;
      }).hex;
      let mockupUrl: string = product.image.url;
      if (templateIdentifier) {
        mockupUrl = url('mockup', mockupRoutes.mockup, {
          designId,
          templateIdentifier: templateIdentifier.id,
          color: designColorHex,
          side: design.sides[0].name,
          size: 'medium',
        });
      }

      // Create grouped items by color
      const productItemKey = `${product.id}_${productItem.color.replace(' ', '-')}_${designId}`;

      if (groupedProductItems[productItemKey]) {
        groupedProductItems[productItemKey].quantity += quantity;
        groupedProductItems[productItemKey].subtotal += amounts.subtotal;
        groupedProductItems[productItemKey].sizes.push({
          name: productItem.size,
          quantity,
          unitPrice: amounts.subtotal / item.product.quantity,
        });
      } else {
        groupedProductItems[productItemKey] = {
          key: productItemKey,
          imageSrc: mockupUrl,
          productName: product.name,
          color: productItem.color,
          quantity,
          subtotal: amounts.subtotal,
          status,
          sizes: [{
            name: productItem.size,
            quantity,
            unitPrice: amounts.subtotal / quantity,
          }],
        };
      }
    });

    orderDetails.items = Object.values(groupedProductItems);

    return orderDetails;
  }

  renderOrderSummary(orderDetails: any) {
    const { orderId, orderedAt, userInfo: { email } } = orderDetails;

    return (
      <div className="flex flex-wrap -my-p25">
        <div className="w-1/2 py-p25">
          <div className={titleClassName}>Order #</div>
          <div>{orderId}</div>
        </div>
        <div className="w-1/2 py-p25">
          <div className={titleClassName}>Ordered Date</div>
          <div>{moment(orderedAt).format('MMMM Do, Y')}</div>
        </div>
        <div className="w-1/2 py-p25">
          <div className={titleClassName}>Customer Email</div>
          <div className="break-words">{email}</div>
        </div>
      </div>
    );
  }

  renderAddress(address: Address) {
    const { name, address1, address2, city, state, zip } = address;
    return (
      <div>
        <span className="fw-bold">{name}</span><br />
        {address1}{address2 && `, ${address2}`}<br />
        {city}, {state} {zip}
      </div>
    );
  }

  renderDetails(orderDetails: any) {
    const { items, amounts: { subtotal, shipping, tax, discount, total } } = orderDetails;

    interface ProductSize { name: string; quantity: number, unitPrice: number };
    const sortSize = (sizes: ProductSize[]): ProductSize[]  => {
      return sizes.sort((size1, size2) => (SIZES_BY_ORDER.indexOf(size1.name) - SIZES_BY_ORDER.indexOf(size2.name)));
    }

    return (
      <div className="mt-1">
        <span className={`${titleClassName} fs-xs`}>Items Ordered</span>
        <div className="bc-navy-200 br bw-1 mt-p5">
          <div className={`flex bc-navy-200 brt bwb-1 bgc-blue-50 py-p25 ${titleClassName}`}>
            <div className="w-1/2 px-p5">Description</div>
            <div className="w-1/6 px-p5">Quantity</div>
            <div className="w-1/6 px-p5 ta-right">Unit Price</div>
            <div className="w-1/6 px-p5 ta-right">Amount</div>
          </div>
          <div>
            {items.map((item: any) => {
              const sizes = sortSize(item.sizes.slice());

              return (
                <div key={item.key} className="flex items-center bc-navy-200 bwb-1 py-p5">
                  <div className="w-1/2 px-p5 flex items-center">
                    <div className="w-3p5 mr-p5">
                      <Image src={item.imageSrc} />
                    </div>
                    <div>
                      {item.productName}<br />
                      Color: {item.color}<br />
                    </div>
                  </div>
                  <div className="w-1/6 px-p5">
                    {sizes.map(({ name, quantity }: { name: string; quantity: number }) => (
                      <div key={name}>{prettyProductSize(name)} ({quantity})</div>
                    ))}
                  </div>
                  <div className="w-1/6 px-p5 ta-right">
                    {sizes.map(({ unitPrice }: { unitPrice: number }, index: number) => (
                      <div key={index}>{prettyPrice(unitPrice)}</div>
                    ))}
                  </div>
                  <div className="w-1/6 px-p5 ta-right">{prettyPrice(item.subtotal)}</div>
                </div>
              );
            })}
          </div>
          <div className="flex flex-row-reverse py-p5">
            <div className="w-1/2 px-p5">
              <NameValueRow name="Subtotal" value={prettyPrice(subtotal)} />
              <NameValueRow name="Shipping & Handling" value={prettyPrice(shipping)} />
              <NameValueRow name="Tax" value={prettyPrice(tax)} />
              <NameValueRow name="Discount" value={prettyPrice(discount > 0 ? -discount : discount)} />
              <NameValueRow name="Total" value={prettyPrice(total)} className="fw-bold fs-xs" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderPayment(orderDetails: any) {
    const { amounts: { total }, payment: { cardNumber, cardType } } = orderDetails;
    return (
      <div className="mt-1">
        <span className={`${titleClassName} fs-xs`}>Payment Info</span>
        <div className="bc-navy-200 br bw-1 mt-p5">
          <div className={`flex items-center bc-navy-200 brt bwb-1 bgc-blue-50 py-p25 ${titleClassName}`}>
            <div className="flex-1 px-p5">Status</div>
            <div className="flex-1 px-p5 ta-center">Payment Method</div>
            <div className="flex-1 px-p5 ta-right">Amount</div>
          </div>
          <div className="flex items-center py-p5">
            <div className="flex-1 px-p5">Paid</div>
            <div className="flex-1 px-p5 ta-center">{cardType} ending in {cardNumber.slice(-4)}</div>
            <div className="flex-1 px-p5 ta-right fs-xs fw-bold">{prettyPrice(total)}</div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      match: {
        params: { orderId },
      },
      location,
    } = this.props;
    const urlQueries = qs.parse(this.props.location.search) || {};
    const email = urlQueries.email;

    return (
      <React.Fragment>
        <Helmet>
          <title>Order Info</title>
        </Helmet>

        <QueryCurrentUser query={QUERY_CURRENT_USER}>
          {({ error: currentUserError, loading: currentUserLoading, data: currentUserData }) => {
            if (currentUserLoading) return <Loading />;
            const user = _.get(currentUserData, 'currentUser');
            const _isUserRegistered = isUserRegistered(user);
            return (
              <Query query={GET_ORDER_BY_ID_AND_EMAIL_QUERY} variables={{ id: orderId, email}} skip={_isUserRegistered}>
                {({ loading: emailOrderLoading, error: emailOrderError, data: emailOrderData }: any) => (
                   <Query query={GET_MY_ORDER_BY_ID_QUERY} variables={{ id: orderId }} skip={!_isUserRegistered}>
                    {({ loading, error, data }: any) => {
                      if (emailOrderLoading || loading) return <Loading />;

                      const order: Order = _.get(data, 'orderByIdAndUser') || _.get(emailOrderData, 'orderByIdAndEmail');
                      if (!orderId || !order || emailOrderError || error) return this.renderOrderNotFound(orderId);

                      const orderDetails = this.createOrderDetails({ order, user });

                      return (
                        <div className="mx-auto px-1 pt-1 pb-2 order-invoice">
                          <style jsx={true}>
                            {`
                              .order-invoice {
                                font-size: 10px;
                              }

                              .order-invoice {
                                width: 612px;
                              }
                            `}
                          </style>

                          <Logo logoSrc={LOGO_SRC} toURL="#" />

                          <div className="flex mt-1 -mx-p5">
                            <div className="flex-1 fs-xs px-p5">
                              <span className="fw-bold">Inkpop</span><br />
                              4145 Christy Street<br />
                              Fremont, CA 94538
                            </div>
                            <div className="flex-1 px-p5">
                              {this.renderOrderSummary(orderDetails)}
                            </div>
                          </div>

                          <div className="flex mt-1 -mx-p5">
                            <div className="flex-1 px-p5">
                              <div className={`${titleClassName} fs-xs`}>Billing Info</div>
                              <div className={`bc-navy-200 bw-1 br-p5 p-p5 mt-p5`}>
                                {this.renderAddress(orderDetails.billingAddress)}
                              </div>
                            </div>
                            <div className="flex-1 px-p5">
                              <div className={`${titleClassName} fs-xs`}>Shipping Info</div>
                              <div className={`bc-navy-200 bw-1 br-p5 p-p5 mt-p5`}>
                                {this.renderAddress(orderDetails.shippingAddress)}
                              </div>
                            </div>
                          </div>

                          {this.renderDetails(orderDetails)}

                          {this.renderPayment(orderDetails)}
                        </div>
                      );
                    }}
                  </Query>
                )}
              </Query>
            )
          }}
        </QueryCurrentUser>


      </React.Fragment>
    );
  }
}

export default withApollo(OrderInvoice);
